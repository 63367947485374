/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Halls from 'components/Halls'

// Elements
import ContentDefault from 'components/elements/ContentDefault'
import HeroDefault from 'components/elements/HeroDefault'
import MoodImage from 'components/elements/MoodImage'
import ContainerSmall from 'components/elements/ContainerSmall'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerContent: banner_content

        moodImage: view_mood_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      content, path, yoastMeta, acf: { bannerContent, backgroundImage, moodImage }
    }
  }
}) => {
  return (
    <Layout headerTop>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={backgroundImage.localFile.childImageSharp.meta.src} article />
      <HeroDefault
        title={bannerContent}
        image={backgroundImage}
      />
      {content && (
        <ContainerSmall className="container py-5">
          <section>
            <ContentDefault content={content} />
          </section>
        </ContainerSmall>
      )}

      <div className="container pb-5">
        <Halls className="mt-5" />
      </div>
      
      {moodImage && (
        <MoodImage image={moodImage} />
      )}
    </Layout>
  )
}

export default PageTemplate